export const CategoryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_178" data-name="Group 178" transform="translate(-16 -8)">
      <rect
        id="Rectangle_1289"
        data-name="Rectangle 1289"
        width="24"
        height="24"
        transform="translate(16 8)"
        fill="none"
      />
      <g id="layout_1_" data-name="layout (1)" transform="translate(16 8)">
        <path
          id="Path_986"
          data-name="Path 986"
          d="M16.6,2h3.6a3.536,3.536,0,0,1,3.6,3.6V22.4A3.536,3.536,0,0,1,20.2,26H16.6A3.536,3.536,0,0,1,13,22.4V5.6A3.536,3.536,0,0,1,16.6,2Z"
          transform="translate(-13 -2)"
          fill="#52dc95"
        />
        <path
          id="Path_987"
          data-name="Path 987"
          d="M5.6,10H9.2a3.536,3.536,0,0,1,3.6,3.6v7.2a3.536,3.536,0,0,1-3.6,3.6H5.6A3.536,3.536,0,0,1,2,20.8V13.6A3.536,3.536,0,0,1,5.6,10Z"
          transform="translate(11.2 -0.4)"
          fill="#cfd1dc"
        />
        <path
          id="Path_988"
          data-name="Path 988"
          d="M5.6,2H9.2a3.536,3.536,0,0,1,3.6,3.6A3.536,3.536,0,0,1,9.2,9.2H5.6A3.536,3.536,0,0,1,2,5.6,3.536,3.536,0,0,1,5.6,2Z"
          transform="translate(11.2 -2)"
          fill="#cfd1dc"
        />
      </g>
    </g>
  </svg>
);
