import { Popover, Tag } from 'antd';
import { FC } from 'react';
import { LanguageModel } from '../../../interfaces';
import { TranslatedTagProps } from './Product.types';

export const TranslatedTag: FC<TranslatedTagProps> = ({ languages, translates }) => {
  const statusTag = (lang: LanguageModel) => {
    return translates?.some(i => i.language_id === lang.id) ? 'success' : 'default';
  };

  return (
    <div className="langauge_container">
      {languages.slice(0, 2).map(item => (
        <Tag color={statusTag(item)} key={item.iso_code} >
          {item.iso_code.toUpperCase()}
        </Tag>
      ))}
      {languages.length >= 3 && (
        <Popover
          overlayClassName="translated_languages_popover"
          placement="bottom"
          content={languages.slice(2).map((item: any) => (
            <Tag color={statusTag(item)} key={item.iso_code}>
              {item.iso_code.toUpperCase()}
            </Tag>
          ))}
          trigger="click"
        >
          <div className="count_language">+{languages.length - 2}</div>
        </Popover>
      )}
    </div>
  );
};
