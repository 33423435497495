export const TranslationProductsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_5540" data-name="Group 5540" transform="translate(-627 -174)">
      <rect
        id="Rectangle_3384"
        data-name="Rectangle 3384"
        width="40"
        height="40"
        rx="20"
        transform="translate(627 174)"
        fill="#81a5f8"
      />
      <g id="language_3_" data-name="language (3)" transform="translate(638 185)">
        <g id="Group_5542" data-name="Group 5542" transform="translate(4.229 4.816)">
          <g id="Group_5541" data-name="Group 5541">
            <path
              id="Path_981"
              data-name="Path 981"
              d="M120.94,137h-.226l-.422,2.109h1.069Z"
              transform="translate(-120.293 -137)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_5544" data-name="Group 5544" transform="translate(13.408 9.035)">
          <g id="Group_5543" data-name="Group 5543">
            <path
              id="Path_982"
              data-name="Path 982"
              d="M381.374,257a5.511,5.511,0,0,0,.866,1.528,5.567,5.567,0,0,0,.9-1.528Z"
              transform="translate(-381.374 -257)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_5546" data-name="Group 5546" transform="translate(8.531 3.199)">
          <g id="Group_5545" data-name="Group 5545">
            <path
              id="Path_983"
              data-name="Path 983"
              d="M250.55,91H244l1.366,10.957a1.637,1.637,0,0,1-.391,1.2l-2.312,2.643h7.887a1.584,1.584,0,0,0,1.582-1.582v-11.6A1.614,1.614,0,0,0,250.55,91Zm0,5.836h-.141a6.691,6.691,0,0,1-1.27,2.31c.387.354.8.645,1.213.97a.527.527,0,0,1-.659.823c-.448-.353-.868-.649-1.288-1.034-.42.385-.8.681-1.252,1.034a.527.527,0,0,1-.659-.823c.412-.325.791-.616,1.178-.97a6.656,6.656,0,0,1-1.235-2.31H246.3a.527.527,0,0,1,0-1.055h1.582v-.527a.527.527,0,0,1,1.055,0v.527h1.617a.527.527,0,1,1,0,1.055Z"
              transform="translate(-242.663 -91)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_5548" data-name="Group 5548">
          <g id="Group_5547" data-name="Group 5547">
            <path
              id="Path_984"
              data-name="Path 984"
              d="M8.584,1.386A1.584,1.584,0,0,0,7.014,0H1.582A1.584,1.584,0,0,0,0,1.582V13.254a1.584,1.584,0,0,0,1.582,1.582H9.9c.154-.176.283-.286.288-.515C10.188,14.263,8.591,1.443,8.584,1.386ZM6.467,10.08a.527.527,0,0,1-.621-.414L5.509,7.98H4.018L3.681,9.666a.527.527,0,1,1-1.034-.207L3.7,4.186a.528.528,0,0,1,.517-.424h1.09a.528.528,0,0,1,.517.424L6.88,9.459A.527.527,0,0,1,6.467,10.08Z"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_5550" data-name="Group 5550" transform="translate(6.162 15.891)">
          <g id="Group_5549" data-name="Group 5549">
            <path
              id="Path_985"
              data-name="Path 985"
              d="M175.261,452l.09.724a1.6,1.6,0,0,0,.912,1.225l1.77-1.949Z"
              transform="translate(-175.261 -452)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
