import { DownOutlined } from '@ant-design/icons';
import { TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Images } from '../../../assets/images/Index';
import { toastNotification } from '../../../utils/toastNotification';

type CategorySelectTreeProp = {
  shopCategories: any;
  onChange: any;
  category: boolean;
  intCategory?: boolean;
  setSaveCategoryId?: any;
  searchParsedSeller?: any;
  placeholder?: string;
};

export const ManageCategorySelectTree = ({
  shopCategories,
  onChange,
  category,
  setSaveCategoryId,
  intCategory,
  searchParsedSeller,
  placeholder,
}: CategorySelectTreeProp) => {
  const [searchTree, setSearchTree] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<string[]>([]);

  const handleSearch = (value: string) => setSearchTree(value);

  const searchInTree = (categories: any[], searchValue: string) => {
    return categories.reduce((filtered, category) => {
      if (category.name.toLowerCase().includes(searchValue.toLowerCase())) {
        filtered.push(category);
      } else if (category.children) {
        const children = searchInTree(category.children, searchValue);
        if (children.length) {
          filtered.push({
            ...category,
            children,
          });
        }
      }
      return filtered;
    }, []);
  };

  const handleOnChange = (newValue: any[]) => {
    const selectedCategoryId = newValue[newValue.length - 1]?.value;
    
    const selectedCategory = shopCategories.find(
      (category: any) => category.originalId === selectedCategoryId
    );
    
    if (selectedCategory?.children) {
      toastNotification('warn', 'Cannot select this parent category!');
      return;
    }

    setValue(newValue);
    if (category) {
      onChange(newValue);
    } else {
      setSaveCategoryId(newValue);
    }
  };

  const filteredCategories = searchInTree(shopCategories, searchTree);

  useEffect(() => {
    setValue([]);
    setSearchTree('');
    searchParams.delete('category');
    setSearchParams(searchParams);
  }, [searchParsedSeller]);

  return (
    <TreeSelect
      disabled={intCategory}
      showSearch
      filterTreeNode={(inputValue, treeNode) =>
        treeNode.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      }
      style={{width: '165px'}}
      bordered={false}
      treeCheckable={true}
      treeCheckStrictly={true}
      showCheckedStrategy={'SHOW_ALL'}
      value={value}
      onChange={handleOnChange}
      onSearch={handleSearch}
      dropdownStyle={{ maxHeight: 500, overflow: 'auto', minWidth: 300 }}
      placeholder={category ? placeholder : 'Category'}
      dropdownMatchSelectWidth={false}
      suffixIcon={<img src={Images.arrowCDown} />}
      showArrow={true}
      getPopupContainer={trigger => trigger.parentNode}
      onClear={() => setSearchTree('')}
      key="originalId"
      allowClear
      treeData={filteredCategories}
      switcherIcon={<DownOutlined />}
    />
  );
};
