export const ServiceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="repair-tool" transform="translate(-2 -2)">
      <path
        id="Path_10686"
        data-name="Path 10686"
        d="M17.429,22.559a9.108,9.108,0,0,1-1.351.429l-.189,2.457a.6.6,0,0,1-.6.556H12.711a.6.6,0,0,1-.6-.555l-.189-2.457a9.146,9.146,0,0,1-2.806-1.165L7.243,23.429a.6.6,0,0,1-.818-.032L4.6,21.575a.6.6,0,0,1-.032-.818l1.605-1.873a9.189,9.189,0,0,1-1.165-2.806l-2.457-.189a.6.6,0,0,1-.555-.6V12.711a.6.6,0,0,1,.555-.6l2.457-.189A9.146,9.146,0,0,1,6.177,9.116l-1.6-1.873A.6.6,0,0,1,4.6,6.426L6.425,4.6a.6.6,0,0,1,.818-.032L9.116,6.178a9.189,9.189,0,0,1,2.806-1.165l.189-2.457a.6.6,0,0,1,.6-.556h2.577a.6.6,0,0,1,.6.555L15.9,2.7a6.413,6.413,0,0,0-.355,6.4,5.144,5.144,0,1,0,1.883,8.731Z"
        fill="#cfd1dc"
      />
      <path
        id="Path_10687"
        data-name="Path 10687"
        d="M25.857,10.4V23.868a2.143,2.143,0,1,1-4.286,0V10.4a4.709,4.709,0,0,1-.151-8.311.1.1,0,0,1,.151.091V7.154l2.143.857,2.143-.857V2.182a.1.1,0,0,1,.151-.09,4.708,4.708,0,0,1-.151,8.31Z"
        transform="translate(-2.429 -0.011)"
        fill="#52dc95"
      />
    </g>
  </svg>
);
