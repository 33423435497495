import { ReactElement } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { AxiosInterceptor } from '../../../AxiosInterceptor';
import { useAuthStore } from '../../../store/index';
import { AppLayout } from '../../layouts/AppLayout';

export const RequireAuthRoute = (): ReactElement => {
  // hooks
  const location = useLocation();
  const { isAuthenticated } = useAuthStore();

  return isAuthenticated ? (
    <AxiosInterceptor>
      <AppLayout>
        <Outlet />
      </AppLayout>
    </AxiosInterceptor>
  ) : (
    <Navigate to={{ pathname: '/login' }} state={{ from: location }} />
  );
};
