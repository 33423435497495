import { ReactElement } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { AxiosInterceptor } from '../../../AxiosInterceptor';
import { useAuthStore } from '../../../store/index';
import { AppLayout } from '../../layouts/AppLayout';

export const ProctetedRoute = ({ allowedRoles }: { allowedRoles: string[] }): ReactElement => {
  // hooks
  const location = useLocation();
  const { user } = useAuthStore();

  const hasAccess = allowedRoles?.some(per => user?.permissions?.some(i => i === per));

  return hasAccess ? <Outlet /> : <Navigate to={{ pathname: '/' }} state={{ from: location }} />;
};
