/* eslint-disable camelcase */
import { PermissionModel } from '../components';
import { groupByProperty } from './groupBy';

export const getFilterPermissions = (
  data: PermissionModel[],
  permissions: { id: string; name: string }[],
  permissionsLabel: string[],
) => {
  const crud = ['Create', 'Read', 'Update', 'Delete'];

  const filtered = data?.map((item: PermissionModel) => {
    const permissionsValues = item.permissions?.map(i => {
      const names = i.name.split('_');
      return { ...i, action: names[0], name: names[1] };
    });

    const group = groupByProperty(permissionsValues, 'name');

    const filter = permissionsLabel.map(label => ({
      role_id: item.id,
      user_id: item.id,
      name: label,
      values: crud.map(action => {
        const obj = group[label]?.find(i => i.action === action);

        const permission_id =
          permissions.find(item => item.name === `${action}_${label}`)?.id ||
          obj?.permission_id ||
          '';

        return {
          ...obj,
          value: !!obj,
          action,
          permission_id,
          name: label,
          user_id: item.id,
          role_id: item.id,
          ...(obj?.id && { id: obj.id }),
        };
      }),
    }));

    return { ...item, permissions: filter };
  });

  return filtered;
};
