import { Form, Input, Tooltip } from 'antd';

const PriceField = ({ index, form, restField, id, shopId }: any) => {
    const name = ['variants', index];
    const priceExtended = form.getFieldValue([...name, 'price_extended']);
    
    // Extract prices from price_extended based on shopId
    const shopPriceData = priceExtended ? priceExtended[Number(shopId)] : null;
    const price = shopPriceData ? Number((shopPriceData.price * (1 + shopPriceData.tvsh / 100)).toFixed(2)) : undefined;
    const discountPrice = shopPriceData ? Number((shopPriceData.discount_price * (1 + shopPriceData.tvsh / 100)).toFixed(2)) : undefined;
    const purchasePrice = form.getFieldValue([...name, 'purchase_price']);
    const effectivePrice = discountPrice !== undefined && discountPrice != 0 ? Number(discountPrice) : Number(price);
    const percentage = (((effectivePrice / purchasePrice) - 1) * 100).toFixed(2);

  return (
    <>
        <Tooltip
        title={(
            <div>
                <p>Purchase Price: {purchasePrice}</p>
                <p>Sales Price: {price}</p>
                <p>Discount S. Price: {discountPrice}</p>
                <p>Percentage: {percentage}%</p>
            </div>
        )}
        >
            {discountPrice == undefined || discountPrice == 0 ? (
                <Form.Item
                {...restField}
                {...(id == 0 && {
                    label: 'S.Price',
                })}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className="read__variant-last"
                >
                <Input
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder="Price"
                    value={price}
                    readOnly
                />
                </Form.Item>
                ) : (
                <Form.Item
                    {...restField}
                    {...(id == 0 && {
                    label: 'S.Price',
                    })}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="read__variant-last"
                >
                    <Input
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder="Price"
                    value={discountPrice}
                    readOnly
                    />
                </Form.Item>)
            }
        </Tooltip>
    </>
  );
};

export default PriceField;