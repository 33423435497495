import ApiConstants from './ApiConstants';
import { toastNotification } from '../utils/toastNotification';
import { readAuthToken } from '../utils/authToken';
import { axiosApi } from '../utils/axiosApiInstance';

export default async function api(path: string, method: string, params: any, additionalHeaders = {}, signal?: AbortSignal, responseType: 'json' | 'blob' = 'json') {
  const token = readAuthToken();
  // const i18nextLng = localStorage.getItem('i18nextLng') || 'en';

  const customOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      ...additionalHeaders,
    },
    method,
    url: ApiConstants.BASE_URL + path,
    ...(params && { data: params }),
    ...(signal && { signal }),
    responseType,
  };

  return axiosApi({ ...customOptions })
    .then((response: any) => {
      if (responseType === 'blob') {
        return Promise.resolve(response);
      }
      return Promise.resolve(response.data);
    })
    .catch((error: any) => {
      if (error?.response?.status === 401) {
        toastNotification('info', 'Session expired, please login again!');
        localStorage.clear();
      } else if (error?.code === 'ERR_CANCELED') {
        console.log('Request canceled', error.message);
      } else {
        return Promise.reject(error?.response?.data || error);
      }
    });
}
