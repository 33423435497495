export const AcademyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g id="graduate" transform="translate(15373 15476)">
      <rect
        id="Rectangle_4829"
        data-name="Rectangle 4829"
        width="30"
        height="30"
        transform="translate(-15373 -15476)"
        fill="none"
      />
      <g id="graduate-cap" transform="translate(-15373 -15476.144)">
        <g id="Group_6282" data-name="Group 6282" transform="translate(0 2.414)">
          <path
            id="Path_10688"
            data-name="Path 10688"
            d="M7.247,17.611v4.233s3.59,3.969,8.641,3.969c6.353,0,8.64-3.969,8.64-3.969V17.252l-7.459,4.359a1.829,1.829,0,0,1-1.742.056Z"
            transform="translate(-0.43 -3.293)"
            fill="#52dc95"
          />
          <path
            id="Path_10689"
            data-name="Path 10689"
            d="M15.645,11.693a4.032,4.032,0,0,1-2.362-.6L4.314,14.2l10.643,5.327a1.82,1.82,0,0,0,1.735-.056l12.4-7.23a1.823,1.823,0,0,0-.1-3.207L16.149,2.607a1.822,1.822,0,0,0-1.735.056L2.012,9.891a1.823,1.823,0,0,0,.1,3.207l-.027,6.564a1.651,1.651,0,0,0-.459,2.864A3.315,3.315,0,0,1,.581,24.55a1.826,1.826,0,0,0,.182,2.82l.228.164a1.815,1.815,0,0,0,1.46.294,1.836,1.836,0,0,0,1.194-.889,6.231,6.231,0,0,0,.161-4.557,1.648,1.648,0,0,0-.6-2.716L3.227,13.4l9.736-3.372a1.806,1.806,0,0,1,.5-.793c-.032.112.031,1.471,2.09,1.471,1.183,0,2.14-.377,2.14-1.149A1.27,1.27,0,0,0,17.6,9.1a2.135,2.135,0,0,1,.532,1.252C18.134,11.253,17.019,11.693,15.645,11.693Z"
            transform="translate(0 -2.414)"
            fill="#cfd1dc"
          />
        </g>
      </g>
    </g>
  </svg>
);
