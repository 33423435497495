export const CompaniesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_5511" data-name="Group 5511" transform="translate(-1609 -174)">
      <rect
        id="Rectangle_3390"
        data-name="Rectangle 3390"
        width="40"
        height="40"
        rx="20"
        transform="translate(1609 174)"
        fill="#d297f9"
      />
      <g id="_04-Building" data-name="04-Building" transform="translate(1619.769 184.769)">
        <path
          id="Path_960"
          data-name="Path 960"
          d="M11.89,2H3.978A1.978,1.978,0,0,0,2,3.978V19.8a.659.659,0,0,0,.659.659h3.3V15.846a.659.659,0,0,1,.659-.659H9.253a.659.659,0,0,1,.659.659v4.615h3.956V3.978A1.978,1.978,0,0,0,11.89,2ZM6.615,13.209H5.3a.659.659,0,0,1,0-1.319H6.615a.659.659,0,0,1,0,1.319Zm0-3.3H5.3a.659.659,0,1,1,0-1.319H6.615a.659.659,0,1,1,0,1.319Zm0-3.3H5.3A.659.659,0,1,1,5.3,5.3H6.615a.659.659,0,1,1,0,1.319Zm3.956,6.593H9.253a.659.659,0,1,1,0-1.319h1.319a.659.659,0,0,1,0,1.319Zm0-3.3H9.253a.659.659,0,1,1,0-1.319h1.319a.659.659,0,1,1,0,1.319Zm0-3.3H9.253a.659.659,0,1,1,0-1.319h1.319a.659.659,0,0,1,0,1.319Z"
          transform="translate(-2 -2)"
          fill="#fff"
        />
        <path
          id="Path_961"
          data-name="Path 961"
          d="M25.3,7H22v3.3h1.978a.659.659,0,1,1,0,1.319H22v1.978h1.978a.659.659,0,1,1,0,1.319H22V16.89h1.978a.659.659,0,1,1,0,1.319H22v3.956h4.615a.659.659,0,0,0,.659-.659V8.978A1.978,1.978,0,0,0,25.3,7Z"
          transform="translate(-8.813 -3.703)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
