import { Button, Form, Input, Modal } from 'antd';
import api from '../../../api';
import { toastNotification } from '../../../utils/toastNotification';

export const ChangePassword = ({ isModalOpen, setIsModalOpen }: any) => {
  const [changePasswordForm] = Form.useForm();
  const handleCancel = () => {
    setIsModalOpen(false);
    changePasswordForm.resetFields();
  };

  const onFinish = (data: any) => {
    api('user/password/change', 'POST', { data })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) {
          if (statusMessage == '"Confirm password" must be [ref:password]') {
            toastNotification('error', "Password and confirm password doesn't match");
            return;
          }
          toastNotification('error', statusMessage);
        }
        if (statusIsOk) {
          toastNotification('success', 'Password changed successfully');
          handleCancel();
          changePasswordForm.resetFields();
        }
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  return (
    <Modal
      width={600}
      className="update__modal reject__modal"
      title=""
      open={isModalOpen}
      onCancel={handleCancel}
      okText="Refuzoni produktin"
    >
      <div>
        <h2>Change password</h2>

        <Form
          id="reject-local-modal"
          className="form-container"
          form={changePasswordForm}
          scrollToFirstError
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="current_password"
            rules={[{ required: true, message: 'Current password is required' }]}
          >
            <Input type="password" placeholder="Current password" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }]}>
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            rules={[{ required: true, message: 'Confirm password is required' }]}
          >
            <Input type="password" placeholder="Confirm password" />
          </Form.Item>
          <div className="modal__update">
            <Button
              className="seller-cancel-button"
              form="reject-local-modal"
              key="submit"
              htmlType="submit"
            >
              Save
            </Button>
            <Button className="seller-save-button" onClick={handleCancel}>
              Close
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
