import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo, useEffect, useState } from 'react';
import queryString from 'query-string';

export const usePersistedShopId = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParsed = useMemo(() => queryString.parse(search), [search]);
  const [shopId, setShopId] = useState<any>(searchParsed.shopId || '1');

  const updateUrl = (newShopId: string) => {
    const newSearch = queryString.stringify({ ...searchParsed, shopId: newShopId }, { skipNull: true });
    navigate({ search: newSearch }, { replace: true });
  };

  useEffect(() => {
    if (searchParsed.shopId !== shopId) {
      updateUrl(shopId);
    }
  }, [shopId]);

  const setPersistedShopId = (newShopId: string) => {
    setShopId(newShopId);
    updateUrl(newShopId);
  };

  return { shopId, setPersistedShopId };
};