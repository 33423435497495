import { Space, Typography } from 'antd';
import { DashboardStatsProps } from './Dashboard.types';

export const DashboardStats = ({ label, value }: DashboardStatsProps) => (
  <Space direction="horizontal" size="small" className="dashboard-card-stats">
    <Typography.Paragraph className="dashboard-card-p">{label}</Typography.Paragraph>
    <Typography.Paragraph className="dashboard-card-p" strong>
      {value.toLocaleString()}
    </Typography.Paragraph>
  </Space>
);
