// jwt decode
import decode from 'jwt-decode';

// config
import { AUTH_TOKEN_KEY } from '../config/env';

// interfaces
import { JWTModel } from '../interfaces';

// storage utils
import { readFromStorage, writeToStorage, removeFromStorage } from './index';

// read auth token from localstorage
export const readAuthToken = (): string | null => {
  return readFromStorage(AUTH_TOKEN_KEY, 'localStorage');
};

/**
 * @param {string} token
 */

// write auth token from localstorage
export const writeAuthToken = (token: string): void => {
  writeToStorage(AUTH_TOKEN_KEY, token, 'localStorage');
};

// remove auth token from localstorage
export const removeAuthToken = (): void => {
  removeFromStorage(AUTH_TOKEN_KEY, 'localStorage');
};

/**
 * @param {string} token
 */

// validate auth token if has expired
export const validateAuthToken = (token: string): boolean => {
  if (token) {
    try {
      const decoded = decode<JWTModel>(token);
      const now = Date.now() / 1000;

      // Check if now (in ms) is less then exp time
      return now <= decoded.exp;
    } catch (error) {
      console.error('👾 invalid token format', error);
      return false;
    }
  }
  return false;
};

// validate auth token if has expired
export const getFieldsAuthToken = (token: string): JWTModel | null => {
  if (token) {
    try {
      return decode<JWTModel>(token);
    } catch (error) {
      console.error('👾 invalid token format', error);
      return null;
    }
  }

  return null;
};
