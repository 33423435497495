export const calculatePercentage = (oldPurchasePrice: number, newPurchasePrice: number) => {
  let percent;
  if (newPurchasePrice !== 0) {
    if (oldPurchasePrice !== 0) {
      percent = ((newPurchasePrice - oldPurchasePrice) / oldPurchasePrice) * 100;
    } else {
      percent = newPurchasePrice * 100;
    }
  } else {
    percent = -oldPurchasePrice * 100;
  }
  return Math.floor(percent);
};

export const calculateTaxPrice = (
  price: number | string | undefined,
  tax: number | string | undefined,
) => {
  const taxRate = Number(tax || 0) / 100 + 1;
  return (Number(price || 0) * taxRate)?.toFixed(2);
};
