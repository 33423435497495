/* eslint-disable camelcase */
import { Space, Checkbox, Button } from 'antd';
import { Controller } from 'react-hook-form';
import { EmployCheckStatus } from './EmployeeCheckStatus';
import { PermissionsTableBodyProps } from './Permissions.types';

export const PermissionsTableBody = ({
  control,
  filedName,
  isEditable,
  handleStatus,
  handleOnChange,
  handleSetUpdates,
  fields: { fields, update },
}: PermissionsTableBodyProps) => {
  // handlers
  const handleSelectAll = (index: number) => () => {
    const value = handleAreSelected(index);

    const updates = fields[index].permissions.map(item => ({
      ...item,
      values: item.values.map(i => ({ ...i, value })),
    }));

    const newChanges = fields[index].permissions
      .map((item, idx) => ({
        ...item,
        values: item.values.map((i, ix) => ({
          ...i,
          value: value && !i.value,
          name: `${i.action}_${item.name}`,
          key: `${filedName}.${index}.permissions.${idx}.values.${ix}.value`,
        })),
      }))
      .map(i => i.values)
      .flat(1)
      .filter(i => i.value === value);

    update(index, { ...fields[index], permissions: updates });

    handleSetUpdates(newChanges);
  };

  const handleAreSelected = (index: number) => {
    const condition = fields[index].permissions.some(item => !item.values.every(i => i.value));
    return condition;
  };

  return (
    <div className="permission-scroll">
      {fields.map(({ id, name, permissions }, index) => (
        <Space key={id} className="permissions-space">
          <div className="permissions-title-content">
            <span className="permissions-title-table">{name}</span>
          </div>
          <Space size="large">
            {permissions.map(({ values }, idx) => (
              <Space className="permissions-space-list" key={idx}>
                {idx === 0 && (
                  <Button
                    type="link"
                    onClick={handleSelectAll(index)}
                    className="permissions-selected"
                  >
                    {`${handleAreSelected(index) ? 'Select all' : 'Deselect all'}`}
                  </Button>
                )}
                <Space className={isEditable ? 'checkbox__gap' : 'icon__gap'}>
                  {values.map((_item, ix) => (
                    <Controller
                      key={`${filedName}.${index}.permissions.${idx}.values.${ix}.value`}
                      control={control}
                      name={`${filedName}.${index}.permissions.${idx}.values.${ix}.value`}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          {isEditable ? (
                            <Checkbox
                              name={name}
                              className={
                                handleStatus(
                                  `${filedName}.${index}.permissions.${idx}.values.${ix}.value`,
                                )
                                  ? 'permissions-checkbox'
                                  : ''
                              }
                              onChange={(e: any) => {
                                handleOnChange(e);
                                onChange(e);
                              }}
                              checked={value}
                              ref={ref}
                            />
                          ) : (
                            <EmployCheckStatus value={value} />
                          )}
                        </>
                      )}
                    />
                  ))}
                </Space>
              </Space>
            ))}
          </Space>
        </Space>
      ))}
    </div>
  );
};
