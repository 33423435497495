import React from 'react';
import { Drawer, Form, Input, Space, Table } from 'antd';
import { useSearchParams } from 'react-router-dom';

export const UserRoleDrawer = ({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (value: boolean) => void;
}) => {
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();

  // handlers
  const handleSetParams = () => setShow(false);

  const dummyData = [
    {
      key: '1',
      permissions: 'User',
    },
    {
      key: '2',
      permissions: 'Admin',
    },
    {
      key: '3',
      permissions: 'Super Admin',
    },
    {
      key: '4',
      permissions: 'Admin 2',
    },
  ];

  return (
    <Drawer
      open={show}
      title="Add Role"
      width={540}
      onClose={handleSetParams}
      visible={searchParams.get('action') === 'create'}
      className="employ-role-drawer"
      footer={
        <div className="user__footer-drawer">
          <button className="form-cancel" onClick={handleSetParams}>
            Cancel
          </button>
          <button className="form-save" color="blue">
            Save
          </button>
        </div>
      }
    >
      <Form
        name="inline"
        layout="vertical"
        scrollToFirstError
        className="form-drawer"
        form={form}
        id="create-role"
      >
        <div className="tw-mb-5">
          <div className="input__user-role">
            <Form.Item name="role_name" label="Role name">
              <Input name="name" type="text" placeholder="Role name" />
            </Form.Item>
          </div>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={4} placeholder="Description" maxLength={6} />
          </Form.Item>

          <Form.Item name="permissions" label="Permissions" required>
            <Table dataSource={dummyData} pagination={false} className="employ-table-drawer">
              <Table.Column title="Task" dataIndex="permissions" width={350} />

              <Table.Column
                title={
                  <div className="user__drawer-role">
                    <p>C</p>
                    <p>R</p>
                    <p>U</p>
                    <p>D</p>
                  </div>
                }
                dataIndex="role_permissions"
                render={e => <Space>{e}</Space>}
              />
            </Table>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};
