import { Space, Typography } from 'antd';

export const PermissionsTableHeader = ({
  permissionsLabel,
  editCheckbox,
}: {
  editCheckbox: any;
  permissionsLabel: string[];
}) => (
  <Space className={editCheckbox ? 'warpper_crud-checkbox' : 'warpper_crud'}>
    {permissionsLabel.map(label => (
      <Space className="permissions-header-list" key={label}>
        <Typography.Paragraph strong style={{ paddingLeft: '8px', textTransform: 'capitalize' }}>
          {label}
        </Typography.Paragraph>
        <Space
          style={{ margin: '0 10px' }}
          className={editCheckbox ? 'text__crud-checkbox' : 'text__crud'}
        >
          {['C', 'R', 'U', 'D'].map(value => (
            <p
              key={value}
              style={{
                margin: 0,
                width: '16px',
              }}
            >
              {value}
            </p>
          ))}
        </Space>
      </Space>
    ))}
  </Space>
);
