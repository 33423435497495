import { ReactElement } from 'react';

export const TotalAssigneIcon = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <g id="total_assigne" transform="translate(-303 -188)">
      <rect
        id="Rectangle_3266"
        data-name="Rectangle 3266"
        width="52"
        height="52"
        rx="26"
        transform="translate(303 188)"
        fill="#fff"
      />
      <g id="checking" transform="translate(316.737 201.734)">
        <path
          id="Path_939"
          data-name="Path 939"
          d="M14.007,2.661a.8.8,0,0,1-.8.8h-4.8a.8.8,0,0,1,0-1.6h.8a.8.8,0,0,0,.8-.8.759.759,0,0,1,.248-.568.787.787,0,0,1,1.12.016.743.743,0,0,1,.232.552.8.8,0,0,0,.8.8h.8A.8.8,0,0,1,14.007,2.661Z"
          transform="translate(-1.744)"
          fill="#3f4773"
        />
        <path
          id="Path_940"
          data-name="Path 940"
          d="M23.095,16.547a.8.8,0,0,1,.358.084l.442.221V14.947h-1.6v1.906l.442-.221a.8.8,0,0,1,.358-.084Z"
          transform="translate(-5.232 -3.484)"
          fill="#6f7596"
        />
        <path
          id="Path_941"
          data-name="Path 941"
          d="M26.151,14.947h-2.4v3.2a.8.8,0,0,1-1.158.716l-1.242-.621-1.242.621a.8.8,0,0,1-1.158-.716v-3.2h-2.4a1.6,1.6,0,0,0-1.6,1.6v9.6a1.6,1.6,0,0,0,1.6,1.6h9.6a1.6,1.6,0,0,0,1.6-1.6v-9.6A1.6,1.6,0,0,0,26.151,14.947Zm-7.2,11.2h-1.6a.8.8,0,0,1,0-1.6h1.6a.8.8,0,0,1,0,1.6Z"
          transform="translate(-3.488 -3.484)"
          fill="#3f4773"
        />
        <path
          id="Path_942"
          data-name="Path 942"
          d="M15.463,2.357H13.719a2.218,2.218,0,0,1,.144.8,2.407,2.407,0,0,1-2.4,2.4h-4.8a2.407,2.407,0,0,1-2.4-2.4,2.218,2.218,0,0,1,.144-.8H2.663a2.407,2.407,0,0,0-2.4,2.4v17.6a2.407,2.407,0,0,0,2.4,2.4h7.632a3.193,3.193,0,0,1-.432-1.6v-.8h-1.6a.8.8,0,0,1,0-1.6h1.6v-1.6h-1.6a.8.8,0,0,1,0-1.6h1.6v-1.6h-1.6a.8.8,0,0,1,0-1.6h1.6v-.8a3.175,3.175,0,0,1,.113-.8H8.263a.8.8,0,0,1,0-1.6h2.7a3.173,3.173,0,0,1,2.1-.8h4.8v-5.6a2.407,2.407,0,0,0-2.4-2.4Zm-10.4,20h-.8a.8.8,0,0,1,0-1.6h.8a.8.8,0,0,1,0,1.6Zm0-3.2h-.8a.8.8,0,0,1,0-1.6h.8a.8.8,0,0,1,0,1.6Zm0-3.2h-.8a.8.8,0,0,1,0-1.6h.8a.8.8,0,0,1,0,1.6Zm0-3.2h-.8a.8.8,0,0,1,0-1.6h.8a.8.8,0,0,1,0,1.6Zm0-3.2h-.8a.8.8,0,0,1,0-1.6h.8a.8.8,0,0,1,0,1.6Zm8.8,0h-5.6a.8.8,0,0,1,0-1.6h5.6a.8.8,0,0,1,0,1.6Z"
          transform="translate(0 -0.496)"
          fill="#6f7596"
        />
      </g>
    </g>
  </svg>
);
