import { ReactElement } from 'react';

export const TranslateIcon = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <g id="total_translated" transform="translate(-621 -188)">
      <rect
        id="Rectangle_3268"
        data-name="Rectangle 3268"
        width="52"
        height="52"
        rx="26"
        transform="translate(621 188)"
        fill="#fff"
      />
      <g id="verification" transform="translate(637 202)">
        <path
          id="Path_935"
          data-name="Path 935"
          d="M22.037,5.65a2.588,2.588,0,1,0,2.588,2.588A2.588,2.588,0,0,0,22.037,5.65ZM23.71,7.915,22.247,9.378a.744.744,0,0,1-.532.225.728.728,0,0,1-.525-.225L20.6,8.793A.74.74,0,0,1,20.6,7.735a.756.756,0,0,1,1.065,0l.052.052.938-.93A.748.748,0,0,1,23.71,7.915Z"
          transform="translate(-4.862 -1.413)"
          fill="#3f4773"
        />
        <path
          id="Path_936"
          data-name="Path 936"
          d="M26.685,5.077a3.333,3.333,0,0,1-.39-.6,3.36,3.36,0,0,1-.158-.742A2.877,2.877,0,0,0,25.447,2a2.877,2.877,0,0,0-1.732-.69,3.36,3.36,0,0,1-.742-.158,3.333,3.333,0,0,1-.6-.39A2.933,2.933,0,0,0,20.625,0a2.913,2.913,0,0,0-1.747.765,3.618,3.618,0,0,1-.6.39,3.457,3.457,0,0,1-.742.157A2.879,2.879,0,0,0,15.8,2a2.913,2.913,0,0,0-.683,1.732,3.853,3.853,0,0,1-.157.743,3.422,3.422,0,0,1-.4.6A2.972,2.972,0,0,0,13.8,6.825a2.951,2.951,0,0,0,.758,1.747,3.724,3.724,0,0,1,.4.6,3.982,3.982,0,0,1,.157.743,2.916,2.916,0,0,0,.683,1.74,2.916,2.916,0,0,0,1.74.683,3.982,3.982,0,0,1,.743.157,3.724,3.724,0,0,1,.6.4,2.951,2.951,0,0,0,1.748.758,2.972,2.972,0,0,0,1.748-.758,3.422,3.422,0,0,1,.6-.4,3.853,3.853,0,0,1,.743-.157,2.913,2.913,0,0,0,1.733-.683,2.879,2.879,0,0,0,.69-1.74,3.457,3.457,0,0,1,.157-.742,3.618,3.618,0,0,1,.39-.6,2.913,2.913,0,0,0,.765-1.747A2.933,2.933,0,0,0,26.685,5.077Zm-6.06,5.835a4.088,4.088,0,1,1,4.087-4.088,4.088,4.088,0,0,1-4.087,4.088Z"
          transform="translate(-3.45)"
          fill="#6f7596"
        />
        <path
          id="Path_937"
          data-name="Path 937"
          d="M20,25h2.25v1.5H20Z"
          transform="translate(-5 -6.25)"
          fill="#6f7596"
        />
        <path
          id="Path_938"
          data-name="Path 938"
          d="M20.49,14.823c-.135.015-.345.052-.42.068-.03.03-.135.113-.247.2a4.362,4.362,0,0,1-2.648,1.057,4.362,4.362,0,0,1-2.647-1.057,3.791,3.791,0,0,0-.315-.225c-.008.008-.225-.03-.352-.045a4.268,4.268,0,0,1-2.572-1.11,4.268,4.268,0,0,1-1.11-2.572c-.015-.128-.052-.345-.067-.413-.03-.03-.113-.135-.2-.248A4.362,4.362,0,0,1,8.85,7.825,4.362,4.362,0,0,1,9.907,5.178a2.286,2.286,0,0,0,.225-.315c-.008-.008.03-.218.045-.345q.034-.236.09-.517H1.5A1.5,1.5,0,0,0,0,5.5v18A1.5,1.5,0,0,0,1.5,25h18A1.5,1.5,0,0,0,21,23.5V14.74C20.82,14.77,20.648,14.8,20.49,14.823ZM4.5,22.75H3a.75.75,0,0,1,0-1.5H4.5a.75.75,0,0,1,0,1.5Zm1.5-3H3a.75.75,0,0,1,0-1.5H6a.75.75,0,0,1,0,1.5ZM18.75,22a.75.75,0,0,1-.75.75H14.25A.75.75,0,0,1,13.5,22V19a.75.75,0,0,1,.75-.75H18a.75.75,0,0,1,.75.75Z"
          transform="translate(0 -1)"
          fill="#3f4773"
        />
      </g>
    </g>
  </svg>
);
