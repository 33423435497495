export const AttributeGroupsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_5538" data-name="Group 5538" transform="translate(-300 -282)">
      <rect
        id="Rectangle_3396"
        data-name="Rectangle 3396"
        width="40"
        height="40"
        rx="20"
        transform="translate(300 282)"
        fill="#4f459a"
      />
      <g id="_x30_1" transform="translate(310.769 292.769)">
        <path
          id="Path_962"
          data-name="Path 962"
          d="M22.418,11.89a1.978,1.978,0,1,0,0-1.319h-.781V4.637h.781a1.978,1.978,0,1,0,0-1.319h-1.44a.659.659,0,0,0-.659.659v6.593H19V11.89h1.319v6.593a.659.659,0,0,0,.659.659h1.44a1.978,1.978,0,1,0,0-1.319h-.781V11.89Z"
          transform="translate(-7.791 -2)"
          fill="#fff"
        />
        <path
          id="Path_963"
          data-name="Path 963"
          d="M10.5,14.19v5.72l4.545-2.031a.659.659,0,0,0,.4-.606V12s0,0,0-.005Z"
          transform="translate(-4.896 -10.679)"
          fill="#fff"
        />
        <path
          id="Path_976"
          data-name="Path 976"
          d="M10.5,14.19v5.72l4.545-2.031a.659.659,0,0,0,.4-.606V12s0,0,0-.005Z"
          transform="translate(-4.896 1.321)"
          fill="#fff"
        />
        <path
          id="Path_964"
          data-name="Path 964"
          d="M7.713,8.054a.655.655,0,0,0-.519,0s-4.65,2-4.669,2.011l4.928,2.19,4.928-2.19C12.363,10.054,7.713,8.054,7.713,8.054Z"
          transform="translate(-2.179 -9.319)"
          fill="#fff"
        />
        <path
          id="Path_975"
          data-name="Path 975"
          d="M7.713,8.054a.655.655,0,0,0-.519,0s-4.65,2-4.669,2.011l4.928,2.19,4.928-2.19C12.363,10.054,7.713,8.054,7.713,8.054Z"
          transform="translate(-2.179 2.681)"
          fill="#fff"
        />
        <path
          id="Path_965"
          data-name="Path 965"
          d="M2,12v5.275a.659.659,0,0,0,.4.606L6.945,19.91V14.19L2,11.992S2,12,2,12Z"
          transform="translate(-2 -10.679)"
          fill="#fff"
        />
        <path
          id="Path_977"
          data-name="Path 977"
          d="M2,12v5.275a.659.659,0,0,0,.4.606L6.945,19.91V14.19L2,11.992S2,12,2,12Z"
          transform="translate(-2 1.321)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
