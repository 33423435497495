import { DeleteOutlined } from '@ant-design/icons';
import { Form, Popconfirm, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { AttributeModel } from '../../../../interfaces';

export interface MappedAttributeProps {
  mappedAttributes: any;
  loading: boolean;
  handleDeleteMapped: (id: string) => void;
}
export const MappedAttribute = ({
  handleDeleteMapped,
  loading,
  mappedAttributes,
}: MappedAttributeProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Attr.Group',
      dataIndex: 'attribute_group_original_name',
      key: 'attribute_group_original_name',
    },
    {
      title: `Attribute`,
      dataIndex: 'attribute_original_name',
      key: 'attribute_original_name',
    },
    {
      title: `Shop Atr.Group`,
      dataIndex: 'mapped_attribute_group_original_name',
      key: 'mapped_attribute_group_original_name',
    },
    {
      title: `Shop Attribute`,
      dataIndex: 'mapped_attribute_original_name',
      key: 'mapped_attribute_original_name',
      render: (text: string, record: AttributeModel) => (
        <span style={{
            textDecoration: record?.is_deleted ? 'line-through' : 'none',
            color: record?.is_deleted ? 'red' : 'none'
          }}
        >
          {text}
        </span>
      ),
    },
    {
      dataIndex: 'id',
      key: 'id',
      render: (e: string) => (
        <Space>
          <Popconfirm
            title={
              <div>
                <p className="title">{t('delete_mapped_attribute')}</p>
                <p className="text">{t('delete_subtite_mapped_attribute')}</p>
              </div>
            }
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDeleteMapped(e)}
          >
            <a className="action-item">
              <DeleteOutlined size={17} style={{ color: 'red' }} alt="Delete" />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="attributes_values attribute_width attribute_product_view_content">
      <div className="attributes_values-center">
        <h2 className="attributes_values_title">Mapped Attributes</h2>
      </div>
      <div>
        <Form form={form} layout="inline" className="mapped__attribute-filter">
          <Table
            scroll={{ x: true }}
            dataSource={mappedAttributes}
            loading={loading}
            pagination={false}
            columns={columns}
            className="attribute_mapped_list"
          />
        </Form>
      </div>
    </div>
  );
};
