import { DownOutlined } from '@ant-design/icons';
import { Button, Input, Select, Tree, TreeProps, Form } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { Images } from '../../../assets/images/Index';
import { MappedAttributesDbModel } from '../../../interfaces/models/MappedAttributes.model';

interface ShopAttributesProps {
  attributes: {
    id: string;
    key: string;
    checkable?: boolean;
    title: string;
    children: { key: string; id: string; title: string; attribute_group_id: string }[];
  }[];
  handleMapFilterAttributes: (data: any) => void;
  setSelectedData: (value: MappedAttributesDbModel) => void;
  selectedShopAttributes?: string[] | [];
  loadingButton: boolean;
  disabled: boolean;
  hideMapSaveBtn: boolean;
  hideSearchBar?: boolean;
  buttonName: string;
  visible?: any;
  title: string;
}

export const ShopwareAttributes = ({
  hideMapSaveBtn,
  disabled,
  attributes,
  hideSearchBar,
  loadingButton,
  setSelectedData,
  selectedShopAttributes,
  handleMapFilterAttributes,
  buttonName,
  visible,
  title,
}: ShopAttributesProps) => {
  const [list, setList] = useState<any>([]);
  const [checked, setChecked] = useState([]);
  const [form] = Form.useForm();
  const [search, setSearch] = useState<{ group: string; attribute: string }>({
    group: '',
    attribute: '',
  });

  const onCheckTreeOptions: TreeProps['onCheck'] = (data: any) => {
    const checkedKeys: any = [...(selectedShopAttributes || []), ...data];
    setSelectedData(checkedKeys);
    setChecked(data);
  };

  const disabledButton = disabled || checked.length === 0;

  const handleSelectGroup = (group: string) => {
    const filtered = attributes?.filter((item: any) => item.id === group);
    setList(filtered);
    setSearch({ ...search, group });
  };

  const handleSearchAttribute = (attribute: string) => {
    const group = attributes?.find(item => item?.id === search.group);
    const children = group?.children?.filter((item: any) =>
      item?.title.toLowerCase().includes(attribute.toLowerCase())
    );
  
    if (!children) {
      return;
    }
  
    const startsWithResults = children.filter(
      item => item.title.toLowerCase().startsWith(attribute.toLowerCase())
    );
  
    const otherResults = children.filter(
      item => !startsWithResults.includes(item)
    );
  
    const sortedStartsWithResults = startsWithResults.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
  
    const sortedOtherResults = otherResults.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
  
    const sortedChildren = [
      ...sortedStartsWithResults,
      ...sortedOtherResults,
    ];
  
    setList([{ ...group, children: sortedChildren }]);
    setSearch({ ...search, attribute });
  };

  useEffect(() => {
    setChecked([]);
  }, [loadingButton]);

  useEffect(() => {
    form.resetFields();
    setList([]);
  }, [visible]);

  return (
    <div className="attributes_values attribute_view_product">
      <div>
        <div className="attribute__wrapper-center">
          <h2 className="attributes_values_title">{title}</h2>
          <div className="mapped_footer">
            {!hideMapSaveBtn ? (
              <Button
                id="map_btn"
                onClick={handleMapFilterAttributes}
                disabled={disabledButton}
                style={{
                  opacity: disabledButton ? '0.5' : '1',
                  cursor: disabledButton ? 'not-allowed' : 'pointer',
                }}
                loading={loadingButton}
              >
                {buttonName}
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="input-search">
          <Form form={form}>
            <Form.Item name="attribute_search">
              <Select
                disabled={disabled}
                style={{ width: '100%', marginBottom: 8 }}
                showSearch
                placeholder="Zgjedhni një grup"
                suffixIcon={<img src={Images.arrowCDown} />}
                optionFilterProp="children"
                options={attributes?.map(item => ({ value: item.id, label: item.title }))}
                onSelect={handleSelectGroup}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                getPopupContainer={trigger => trigger.parentNode}
              />
            </Form.Item>

            {!hideSearchBar && (
              <Input.Search
                disabled={disabled}
                allowClear
                style={{ marginBottom: 8 }}
                onSearch={handleSearchAttribute}
                placeholder="Kërkoni në bazë të atributit"
              />
            )}
          </Form>
        </div>

        <Tree
          disabled={disabled}
          className="tree_bg_color"
          checkable
          treeData={list}
          fieldNames={{ key: 'id' }}
          key="id"
          checkedKeys={checked}
          switcherIcon={<DownOutlined />}
          {...(search?.group && { expandedKeys: [list[0]?.id || ''] })}
          onCheck={onCheckTreeOptions}
        />
      </div>
    </div>
  );
};
