import { Button, Form, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

type UpdateModalProps = {
  modal: boolean;
  closeModal: () => void;
  onFinishModal: (data: any) => void;
};

export const UpdateSellerModal = ({ modal, closeModal, onFinishModal }: UpdateModalProps) => {
  const [form] = Form.useForm();

  return (
    <Modal
      width={600}
      className="update__modal"
      title=""
      open={modal}
      onCancel={closeModal}
      okText="Dërgo tek selleri"
    >
      <div>
        <h2>Cfare deshironi te ndryshoni ne profilin tuaj?</h2>

        <Form
          id="update-local-modal"
          className="form-container"
          form={form}
          scrollToFirstError
          layout="vertical"
          onFinish={onFinishModal}
        >
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="data"
            label="Shkruani ketu"
          >
            <TextArea placeholder="Shkruani ketu..." />
          </Form.Item>
          <div className="modal__update">
            <Button className="seller-cancel-button" onClick={closeModal}>
              Mbyll
            </Button>

            <Button
              className="seller-save-button"
              form="update-local-modal"
              key="submit"
              htmlType="submit"
            >
              Dergo email
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
