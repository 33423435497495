export const OrdersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.001" viewBox="0 0 24 24.001">
    <g id="Group_178" data-name="Group 178" transform="translate(-16 -8)">
      <rect
        id="Rectangle_1289"
        data-name="Rectangle 1289"
        width="24"
        height="24"
        transform="translate(16 8)"
        fill="none"
      />
      <g id="Group_5559" data-name="Group 5559" transform="translate(15545 17694)">
        <path
          id="bag_1_"
          data-name="bag (1)"
          d="M9.008,6.25a5.218,5.218,0,0,0-5.128,4.6L2.864,18.665a5.259,5.259,0,0,0,5.128,6h9.652a5.259,5.259,0,0,0,5.128-6l-1.016-7.814a5.218,5.218,0,0,0-5.128-4.6Zm3.843,12.592a.8.8,0,0,1-1.155,0L9.518,16.609a.852.852,0,0,1,0-1.184.8.8,0,0,1,1.155,0l1.6,1.64,3.779-3.873a.8.8,0,0,1,1.155,0,.852.852,0,0,1,0,1.184Z"
          transform="translate(-15529.818 -17686.668)"
          fill="#cfd1dc"
          fillRule="evenodd"
        />
        <path
          id="bag_1_2"
          data-name="bag (1)"
          d="M7.25,6.552a5.239,5.239,0,0,1,5.173-5.3,5.239,5.239,0,0,1,5.173,5.3V9.9a.817.817,0,1,1-1.634,0V6.552a3.541,3.541,0,1,0-7.079,0V9.9a.817.817,0,1,1-1.634,0Z"
          transform="translate(-15529.423 -17687.25)"
          fill="#52dc95"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
