import { DownOutlined } from '@ant-design/icons';
import { Button, Input, Select, Tree, TreeProps, Form } from 'antd';
import { useState, useEffect } from 'react';
import { MappedAttributesDbModel } from '../../../interfaces/models/MappedAttributes.model';
import Api from '../../../api/index';
import { toastNotification } from '../../../utils/toastNotification';
import { AttributeModel } from '../../../interfaces';
import { useDebounce } from '../../../hooks/useDebounce';

interface ShopAttributesProps {
  handleMapFilterAttributes: (data: any) => void;
  setSelectedData: (value: MappedAttributesDbModel) => void;
  selectedShopAttributes?: string[] | [];
  loadingButton: boolean;
  hideMapSaveBtn: boolean;
  buttonName: string;
  visible?: any;
  title: string;
  sellerId: string;
}

export const ShopwareAttributesFilter = ({
  hideMapSaveBtn,
  loadingButton,
  setSelectedData,
  selectedShopAttributes,
  handleMapFilterAttributes,
  buttonName,
  visible,
  title,
  sellerId,
}: ShopAttributesProps) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [checked, setChecked] = useState([]);
  const [form] = Form.useForm();
  const [search, setSearch] = useState<{ group: string; attribute: string }>({
    group: '',
    attribute: '',
  });

  const [attributes, setAttributes] = useState<
    {
      id: string;
      key: string;
      checkable?: boolean;
      title: string;
      children: { key: string; id: string; title: string; attribute_group_id: string }[];
    }[]
  >([]);

  const onCheckTreeOptions: TreeProps['onCheck'] = (data: any) => {
    const checkedKeys: any = [...(selectedShopAttributes || []), ...data];
    setSelectedData(checkedKeys);
    setChecked(data);
  };

  const disabledButton = loadingButton || checked.length === 0;

  const handleSetError = (msg: string) => {
    toastNotification('error', msg);
    setLoading(false);
  };

  const handleGetAttGroupsBySearch = (search: string) => {
    Api(`mapped-attributes/getAttributeGroupsBySellerId/${sellerId}?search=${search}`, 'GET', null)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          const filtered = result?.map((item: any) => ({
            key: item?.id,
            id: item?.id,
            title: item?.original_name,
            checkable: false,
            selectable: false,
            children: item?.attributes
            ?.filter((attribute: AttributeModel) => !attribute.is_deleted)
            ?.map((attribute: any) => ({
              id: attribute.id,
              selectable: false,
              title: attribute?.original_name,
            })),
          }));

          setAttributes(filtered);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const handleSearch = useDebounce((term: string) => {
    if (term.length > 2) {
      handleGetAttGroupsBySearch(term);
    }
  }, 500);

  const handleSelectGroup = useDebounce((groupId: string) => {
    setLoading(true);
    Api(`attribute-group/${groupId}`, 'GET', null)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          const group = {
            key: result?.id,
            id: result?.id,
            title: result?.original_name,
            checkable: false,
            selectable: false,
            children: result?.attributes
              ?.filter((attribute: AttributeModel) => !attribute.is_deleted)
              ?.map((attribute: any) => ({
                id: attribute.id,
                selectable: false,
                title: attribute?.original_name,
              })),
          };
          setList([group]);
          setSearch({ ...search, group: groupId });
          setLoading(false);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  }, 500);

  const handleSearchAttribute = (attribute: string) => {
    const group = attributes?.find(item => item?.id === search.group);
    const children = group?.children?.filter((item: any) =>
      item?.title.toLowerCase().includes(attribute.toLowerCase()),
    );

    setList([{ ...group, children }]);
    setSearch({ ...search, attribute });
  };

  useEffect(() => {
    setChecked([]);
  }, [loadingButton]);

  useEffect(() => {
    form.resetFields();
    setList([]);
  }, [visible]);

  return (
    <div className="attributes_values attribute_view_product">
      <div>
        <div className="attribute__wrapper-center">
          <h2 className="attributes_values_title">{title}</h2>
          <div className="mapped_footer">
            {!hideMapSaveBtn ? (
              <Button
                id="map_btn"
                onClick={handleMapFilterAttributes}
                disabled={disabledButton}
                style={{
                  opacity: disabledButton ? '0.5' : '1',
                  cursor: disabledButton ? 'not-allowed' : 'pointer',
                }}
                loading={loadingButton}
              >
                {buttonName}
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="input-search">
          <Form form={form}>
            <Form.Item name="attribute_search">
              <Select
                onClear={() => {
                  setList([]);
                  setSearch({ group: '', attribute: '' });
                }}
                allowClear
                disabled={loadingButton}
                style={{ width: '100%', marginBottom: 8 }}
                showSearch
                placeholder="Zgjedhni një grup"
                options={attributes?.map(item => ({ value: item.id, label: item.title }))}
                onSelect={handleSelectGroup}
                onSearch={handleSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                getPopupContainer={trigger => trigger.parentNode}
              />
            </Form.Item>

            <Input.Search
              disabled={loadingButton}
              allowClear
              style={{ marginBottom: 8 }}
              onSearch={handleSearchAttribute}
              placeholder="Kërkoni në bazë të atributit"
            />
          </Form>
        </div>

        <Tree
          disabled={loadingButton || loading}
          className="tree_bg_color"
          checkable
          treeData={list}
          fieldNames={{ key: 'id' }}
          key="id"
          checkedKeys={checked}
          switcherIcon={<DownOutlined />}
          {...(search?.group && { expandedKeys: [list[0]?.id || ''] })}
          onCheck={onCheckTreeOptions}
        />
      </div>
    </div>
  );
};
