import { useEffect, useState } from 'react';

import Api from '../api/index';
import { useAuthStore } from '../store';
import { readAuthToken, removeAuthToken, validateAuthToken } from '../utils/authToken';

export const useAuth = (): { loading: boolean } => {
  const { addAuth, removeAuth } = useAuthStore();
  const token = readAuthToken() || '';
  const [loading, setLoading] = useState(true);

  const handleGetUserById = () => {
    Api('auth/me', 'GET', null)
      .then(({ result }) => {
        const roles = result.users_roles?.map(({ name }: { name: string }) => name);
        const permissions = result.permissions?.map(({ name }: { name: string }) => name) || [''];

        addAuth(
          {
            seller: result?.seller?.name,
            roles,
            permissions,
            email: result.email,
            companyId: result.company.id,
            fullName: `${result.first_name} ${result.last_name}`,
            id: result.id,
          },
          true,
        );
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (validateAuthToken(token)) {
      handleGetUserById();
    } else {
      removeAuthToken();
      removeAuth();
      setLoading(false);
    }
  }, []);

  return { loading };
};
