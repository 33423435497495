type IndexableKeyTypes = string | number | symbol;

type Indexable<T = unknown> = Record<string | number, T>;

type JustIndexableTypes<T> = T extends IndexableKeyTypes ? T : never;

type KeysMatching<Rec, Keys> = NonNullable<
  {
    [RecKey in keyof Rec]: Rec[RecKey] extends Keys ? RecKey : never;
  }[keyof Rec]
>;

type GroupBy<T extends Indexable, K extends IndexableKeys<T>> = {
  [KV in JustIndexableTypes<T[K]>]: Array<T extends Record<K, KV> ? T : never>;
};

type IndexableKeys<Rec> = KeysMatching<Rec, IndexableKeyTypes>;

export const groupByProperty = <Obj extends Indexable, KeyName extends IndexableKeys<Obj>>(
  xs: Obj[],
  keyName: KeyName,
): GroupBy<Obj, KeyName> => {
  type KeyValue = JustIndexableTypes<Obj[KeyName]>;
  const seed = {} as GroupBy<Obj, KeyName>;

  return xs.reduce((groupings, x) => {
    const groupName = x[keyName] as KeyValue;

    if (groupings[groupName] === undefined) {
      groupings[groupName] = [];
    }

    groupings[groupName].push(x as Obj extends Record<KeyName, KeyValue> ? Obj : never);

    return groupings;
  }, seed);
};
