export const PDFIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.403"
    height="15.451"
    viewBox="0 0 15.403 15.451"
  >
    <path
      id="Path_753"
      data-name="Path 753"
      d="M30.154,10.036a3.332,3.332,0,0,1-.146,1.415c-.65,2.109-2.825,9.8-4.468,10.888-2.344,1.566-3.563-.213-2.314-1.477s8.783-2.947,11.185-2.947c3.125,0,2.723,2.6.949,2.445-2-.179-7-3.387-7.3-9.486C27.927,8.7,29.928,8.3,30.154,10.036Z"
      transform="translate(-22.027 -8.246)"
      fill="none"
      stroke="#c84d52"
      strokeWidth="1.4"
    />
  </svg>
);
