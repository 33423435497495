/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useFieldArray, useForm } from 'react-hook-form';
import { Modal, Space, Typography, Button, Form } from 'antd';

import Api from '../../../api/index';
import { getFilterPermissions } from '../../../utils';
import { PermissionsActions } from './PermissionsActions';
import { PermissionsTableBody } from './PermissionsTableBody';
import { PermissionsTableHeader } from './PermissionsTableHeader';
import { toastNotification } from '../../../utils/toastNotification';
import { PermissionOption, PermissonsInputs, RolesFormProps } from './Permissions.types';

export const UsersForm = ({ editCheckbox, handleGetAuthMe, handleGoBack }: RolesFormProps) => {
  // hooks
  const [loading, setLoading] = useState(false);
  const { control, reset } = useForm<PermissonsInputs>({
    defaultValues: {
      users: [],
    },
  });
  const [usersModal, setUsersModal] = useState(false);
  const [headerLabel, setHeaderLabel] = useState<string[]>([]);
  const [updateUsers, setUpdateUsers] = useState<PermissionOption[]>([]);

  const usersFields = useFieldArray({ control, name: 'users' });

  // handlers
  const handleSetUsersModal = (value: boolean) => () => setUsersModal(value);
  const handleStatusUsers = (id: string) => updateUsers.find(item => item.key === id);

  const handleSetError = (msg: string) => {
    toastNotification('error', msg);
    setLoading(false);
  };

  const handleSetUserUpdates = (values: PermissionOption[]) => {
    const filter = values.filter(item => !updateUsers.find(i => i.key === item.key));
    const oldUpdates = updateUsers.filter(item => !values.find(i => i.key === item.key));
    setUpdateUsers([...oldUpdates, ...filter]);
  };

  const handleOnChangeUser = ({ target }: CheckboxChangeEvent) => {
    const exists = updateUsers.find(item => item.key === target.name);
    const splitKey = target.name?.split('.');
    const current =
      usersFields.fields[+(splitKey?.[1] || 0)].permissions[+(splitKey?.[3] || 0)].values.flat(1)[
        +(splitKey?.[5] || 0)
      ];

    setUpdateUsers([
      ...updateUsers.filter(item => item.key !== target.name),
      ...(exists
        ? []
        : [
            {
              key: target.name || '',
              value: target.checked,
              user_id: current.user_id,
              ...(current.permission_id && { permission_id: current.permission_id }),
              ...(current.id && { id: current.id }),
            },
          ]),
    ]);
  };

  const handleGetPermissions = () => {
    setLoading(true);
    Api('permission/manage/users', 'GET', null)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          const labels = result.permissions
            .filter((i: { name: string }) => i.name.split('_').includes('Read'))
            .map((label: { name: string }) => label.name.split('_')[1]);
          setHeaderLabel(labels);

          const users = getFilterPermissions(result.results, result.permissions, labels);
          reset({ users });
          setLoading(false);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const handleSubmit = () => {
    const data = updateUsers.map(i => ({
      value: i.value,
      user_id: i.user_id,
      permission_id: i.permission_id,
      ...(i.id && { id: i.id }),
    }));

    setLoading(true);
    Api('permission/manage/users/upsert', 'POST', { data })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setLoading(false);
          setUsersModal(false);
          setUpdateUsers([]);
          toastNotification('success', 'Successfully updated!');
          handleGetAuthMe();
          handleGoBack();
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  useEffect(() => {
    handleGetPermissions();
  }, []);

  return (
    <>
      <Form className="permissions-form">
        <Modal
          open={usersModal}
          onOk={handleSubmit}
          confirmLoading={loading}
          title="Confrim"
          onCancel={handleSetUsersModal(false)}
        >
          Are you sure you want to update these permissions?
        </Modal>
        <Space className="permissions-header">
          <Typography.Paragraph className="permissions-title">Name</Typography.Paragraph>
          <PermissionsTableHeader permissionsLabel={headerLabel} editCheckbox={editCheckbox} />
        </Space>

        <PermissionsTableBody
          fields={usersFields}
          control={control}
          filedName="users"
          handleStatus={handleStatusUsers}
          handleOnChange={handleOnChangeUser}
          handleSetUpdates={handleSetUserUpdates}
          isEditable={editCheckbox}
        />
      </Form>
      <PermissionsActions>
        <Space>
          {editCheckbox && (
            <Button className="permissions-save-btn" onClick={handleSetUsersModal(true)}>
              Save
            </Button>
          )}
        </Space>
      </PermissionsActions>
    </>
  );
};
