export const SettingsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_178" data-name="Group 178" transform="translate(-16 -8)">
      <rect
        id="Rectangle_1289"
        data-name="Rectangle 1289"
        width="24"
        height="24"
        transform="translate(16 8)"
        fill="none"
      />
      <g id="Layer_2" data-name="Layer 2" transform="translate(14.822 6.23)">
        <path
          id="Path_2573"
          data-name="Path 2573"
          d="M20.052,4A2.418,2.418,0,0,0,17.979,2.77h-9.6A2.418,2.418,0,0,0,6.3,4L1.5,12.539a2.553,2.553,0,0,0,0,2.494l4.8,8.506A2.418,2.418,0,0,0,8.377,24.77h9.6a2.418,2.418,0,0,0,2.073-1.231L24.853,15a2.553,2.553,0,0,0,0-2.494ZM17.226,13.77a4.049,4.049,0,1,1-4.048-4.157,4.1,4.1,0,0,1,4.048,4.157Z"
          fill="#cfd1dc"
        />
      </g>
    </g>
  </svg>
);
