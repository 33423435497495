import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import App from './App';
import './sass/main.scss';
import './i18n';

import { Loader } from './components/shared';
import { BrowserRouter } from 'react-router-dom';
import { NODE_ENV } from './config/env';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        {NODE_ENV !== 'production' && (
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
        )}

        <App />
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
