export const ProductIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_5534" data-name="Group 5534" transform="translate(-954 -174)">
      <rect
        id="Rectangle_3386"
        data-name="Rectangle 3386"
        width="40"
        height="40"
        rx="20"
        transform="translate(954 174)"
        fill="#f5c271"
      />
      <path
        id="box"
        d="M10.672,11.4a4.379,4.379,0,0,0,.79.247v9.812a2.7,2.7,0,0,1-.482-.175L4.826,18.532A3.093,3.093,0,0,1,3,15.708V8.844a3.059,3.059,0,0,1,.113-.814Zm4.451-2.3L6.764,5.154,4.826,6.02a2.983,2.983,0,0,0-.933.649l7.395,3.308a2.316,2.316,0,0,0,1.887,0Zm5.446-2.432a2.983,2.983,0,0,0-.933-.649L13.482,3.268a3.029,3.029,0,0,0-2.5,0L8.631,4.319,16.99,8.267Zm.779,1.36-3.6,1.608v2.525a.769.769,0,1,1-1.538,0V10.318L13.79,11.4a5.391,5.391,0,0,1-.79.247v9.812a2.7,2.7,0,0,0,.482-.175l6.154-2.752a3.093,3.093,0,0,0,1.826-2.824V8.844a3.059,3.059,0,0,0-.113-.814Z"
        transform="translate(961.769 181.77)"
        fill="#fff"
      />
    </g>
  </svg>
);
