import { useEffect } from 'react';

export const useDebounce = (callback: (value: string) => void, delay: number) => {
  let timeoutId: undefined | ReturnType<typeof setTimeout>;

  useEffect(() => {
    // Cleanup the previous timeout on re-render
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const debouncedCallback = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      callback(value);
    }, delay);
  };

  return debouncedCallback;
};
