/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Collapse } from 'antd';
import * as Icon from 'react-feather';

import { UsersForm } from './UserForm';
import { RolesForm } from './RolesForm';
import { UserRoleDrawer } from './UserRoleDrawer';
import { useAuthStore } from '../../../store';
import Api from '../../../api/index';

export const TabAccess = ({ showAdd, setShowAdd }: { showAdd: boolean; setShowAdd: any }) => {
  const [editCheckbox, setEditCheckbox] = useState(false);
  const [show, setShow] = useState(false);

  // hooks
  const { addAuth } = useAuthStore();

  const showDrawer = () => setShow(true);

  const toggleRole = () => {
    setEditCheckbox(true);
    setShowAdd(false);
  };

  const goBackButton = () => {
    setShowAdd(true);
    setEditCheckbox(false);
  };

  const handleGetAuthMe = () => {
    Api('auth/me', 'GET', null)
      .then(({ result }) => {
        const roles = result.users_roles?.map(({ name }: { name: string }) => name);
        const permissions = result.permissions?.map(({ name }: { name: string }) => name) || [''];

        addAuth(
          {
            seller: result?.seller?.name,
            roles,
            permissions,
            email: result.email,
            companyId: result.company.id,
            fullName: `${result.first_name} ${result.last_name}`,
            id: result.id,
          },
          true,
        );
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="permissions-edit-role-btn">
        {showAdd ? (
          <button className="edit_role_btn" onClick={toggleRole}>
            Edit Role
          </button>
        ) : (
          <>
            <button className="edit_role_btn" onClick={goBackButton}>
              <Icon.ChevronLeft size={14} /> Back
            </button>
            <button className="edit_role_btn" onClick={showDrawer}>
              Add Role
            </button>
          </>
        )}
      </div>
      <Collapse
        defaultActiveKey={['generalAccess']}
        className="permissions-collapse"
        bordered={false}
      >
        <Collapse.Panel header="General access" key="generalAccess">
          <RolesForm
            editCheckbox={editCheckbox}
            handleGetAuthMe={handleGetAuthMe}
            handleGoBack={goBackButton}
          />
        </Collapse.Panel>
      </Collapse>

      <Collapse
        defaultActiveKey={['individualAccess']}
        className="permissions-collapse"
        bordered={false}
      >
        <Collapse.Panel header="Individual access" key="individualAccess">
          <UsersForm
            handleGoBack={goBackButton}
            editCheckbox={editCheckbox}
            handleGetAuthMe={handleGetAuthMe}
          />
        </Collapse.Panel>
      </Collapse>

      <UserRoleDrawer show={show} setShow={setShow} />
    </>
  );
};
