import { DownOutlined } from '@ant-design/icons';
import { Col, Collapse, Form, Row, Tree, TreeProps } from 'antd';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import Api from '../../../../api/index';
import { toastNotification } from '../../../../utils/toastNotification';
import { ShopCategoriesTree } from '../Product.types';

export const ShopProductCategories = ({
  loading,
  visible,
  categories,
  product_id,
  handleSelect,
}: ShopCategoriesTree) => {
  const [selected, setSelected] = useState<any>([]);
  const [activeKey, setActiveKey] = useState(['0']);

  const onCheckTreeOptions: TreeProps['onCheck'] = (checkedKeys: any, info: any) => {
    if (info.checked && info.node.children) {
      checkedKeys.checked.pop();
      toastNotification('warn', 'Cannot check this parent node!');
      return;
    }
    handleSelect(checkedKeys?.checked);
    setSelected(checkedKeys?.checked);
  };

  const getCategoriesByProduct = () => {
    Api(`mapped-category/categoriesByProduct/${product_id}`, 'POST', null)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          setSelected(result);
          handleSelect(result);
        }
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const handleCollapse = (key: string | string[]) => {
    const newKey = Array.isArray(key) ? key : [key];
    setActiveKey(newKey as string[]);
  };

  useEffect(() => {
    getCategoriesByProduct();

    return () => {
      setSelected([]);
    };
  }, []);

  useEffect(() => {
    setActiveKey(selected.length > 0 ? ['1'] : ['0']);
  }, [selected]);

  return (
    <Row gutter={16}>
      <Col span={24} className="category__wrapper">
        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} className="category-tree">
          <Col span={24} className="category-col">
            <Collapse
              activeKey={activeKey}
              onChange={handleCollapse}
              expandIconPosition="end"
              expandIcon={({ isActive }) => <DownOutlined size={18} rotate={isActive ? 0 : 180} />}
            >
              <p>
                <span className="color-red">*</span>Zgjedhni kategoritë
              </p>
              <Collapse.Panel header="Kategoritë" key="1">
                <Tree.DirectoryTree
                  key="id"
                  checkable
                  checkStrictly
                  showIcon={false}
                  defaultCheckedKeys={selected}
                  defaultExpandedKeys={selected}
                  fieldNames={{ key: 'originalId' }}
                  height={window.screen.height * 0.9}
                  switcherIcon={<DownOutlined />}
                  onCheck={onCheckTreeOptions}
                  treeData={categories}
                />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Form.Item>
      </Col>
    </Row>
  );
};
