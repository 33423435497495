import { ReactElement, useEffect } from 'react';
import { useAuthStore } from './store';
import { readAuthToken, removeAuthToken } from './utils/authToken';
import { axiosApi, checkIsActiveAccount } from './utils/axiosApiInstance';
// import { toastNotification } from './utils/toastNotification';
import axios, { AxiosRequestConfig } from 'axios';
const AxiosInterceptor = ({ children }: { children: ReactElement }) => {
  const { removeAuth } = useAuthStore();

  useEffect(() => {
    axiosApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
      const token = readAuthToken();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      if (token) {
        const response = await checkIsActiveAccount();
        if (!response.data?.result?.active) {
          config.cancelToken = source.token;
          removeAuth();
          removeAuthToken();
          //   toastNotification('info', 'Your account has been deactivated!');
          return Promise.reject(config);
        }
      }

      return config;
    });
  }, []);

  return children;
};

export default axiosApi;
export { AxiosInterceptor };
