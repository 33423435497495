const ENV = process.env.REACT_APP_MODE;
const URL = process.env.REACT_APP_API;

const ApiConstants = {
  mode: ENV,
  BASE_URL: URL,
  TOKEN: localStorage.getItem('foleja-token'),
};

export default ApiConstants;
