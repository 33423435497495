import React from 'react';
import { Row, Spin, SpinProps } from 'antd';

export const Loader = ({ size = 'large', ...rest }: SpinProps) => (
  <Row
    justify="center"
    align="middle"
    style={{ minHeight: '100vh', display: 'flex' }}
  >
    <Spin size={size} {...rest} />
  </Row>
);
