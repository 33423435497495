export const LocalSellers = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.004" viewBox="0 0 24 24.004">
    <g id="Group_178" data-name="Group 178" transform="translate(-16 -7.996)">
      <rect
        id="Rectangle_1289"
        data-name="Rectangle 1289"
        width="24"
        height="24"
        transform="translate(16 8)"
        fill="none"
      />
      <g id="Group_5789" data-name="Group 5789" transform="translate(15491 17414.996)">
        <path
          id="store"
          d="M25.64,16.422v6.551a3.531,3.531,0,0,1-3.995,3.995H18.09a.4.4,0,0,1-.4-.4v-4.1a2.779,2.779,0,0,0-2.208-2.787,2.67,2.67,0,0,0-3.118,2.624v4.261a.4.4,0,0,1-.4.4H8.33a3.531,3.531,0,0,1-3.995-3.995V16.422a5.9,5.9,0,0,0,2.53.559,6.666,6.666,0,0,0,4.088-1.411,6.491,6.491,0,0,0,8.082,0,6.666,6.666,0,0,0,4.088,1.411,5.881,5.881,0,0,0,2.517-.559Z"
          transform="translate(-15477.988 -17410)"
          fill="#cfd1dc"
        />
        <path
          id="store-2"
          data-name="store"
          d="M9,3C5.667,3,5.157,4.092,4.608,5.714L3.224,9.805a3.893,3.893,0,0,0,1.6,4.635,4.072,4.072,0,0,0,2.044.543,4.369,4.369,0,0,0,4.068-2.663,4.418,4.418,0,0,0,8.109,0,4.369,4.369,0,0,0,4.068,2.663,4.072,4.072,0,0,0,2.045-.543,3.893,3.893,0,0,0,1.6-4.635L25.374,5.714C24.818,4.092,24.308,3,20.98,3Z"
          transform="translate(-15477.988 -17410)"
          fill="#52dc95"
        />
      </g>
    </g>
  </svg>
);
