import axios from 'axios';
import ApiConstants from '../api/ApiConstants';
import { useAuthStore } from '../store';
import { readAuthToken, removeAuthToken } from './authToken';
import { toastNotification } from './toastNotification';

export const checkIsActiveAccount = async () => {
  const token = readAuthToken();

  const response = await axios({
    method: 'GET',
    url: `${ApiConstants.BASE_URL}auth/check`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => response)
    .catch(function (err) {
      removeAuthToken();
      //   toastNotification('info', 'Session expired, please login again!');
      return err;
    });

  return response;
};

export const axiosApi = axios.create();

// Allow automatic updating of access token
axiosApi.interceptors.request.use(async (request: any) => {
  const token = readAuthToken();
  if (token) {
    const response = await checkIsActiveAccount();
    if (!response.data?.result?.active) {
      console.log('Logout');
      // window.location = '/';
      const store = useAuthStore();
      removeAuthToken();
      store.removeAuth();
      toastNotification('info', 'Your account has been deactivated!');
      return Promise.reject(request);
    }
  }

  return request;
});
