/* eslint-disable camelcase */
import { Modal, Space, Typography, Button, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toastNotification } from '../../../utils/toastNotification';
import { PermissionOption, PermissonsInputs, RolesFormProps } from './Permissions.types';
import { PermissionsActions } from './PermissionsActions';
import { PermissionsTableBody } from './PermissionsTableBody';
import { PermissionsTableHeader } from './PermissionsTableHeader';
import Api from '../../../api/index';
import { getFilterPermissions } from '../../../utils';

export const RolesForm = ({ editCheckbox, handleGetAuthMe, handleGoBack }: RolesFormProps) => {
  const [rolesModal, setRolesModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [headerLabel, setHeaderLabel] = useState<string[]>([]);
  const [updateRoles, setUpdateRoles] = useState<PermissionOption[]>([]);

  // hooks
  const { control, reset } = useForm<PermissonsInputs>();
  const rolesFields = useFieldArray({ control, name: 'roles' });

  // handlers
  const handleSetRolesModal = (value: boolean) => () => setRolesModal(value);
  const handleStatusRoles = (id: string) => updateRoles.find(item => item.key === id);

  const handleSetError = (msg: string) => {
    toastNotification('error', msg);
    setLoading(false);
  };

  const handleSetRoleUpdates = (values: PermissionOption[]) => {
    const filter = values.filter(item => !updateRoles.find(i => i.key === item.key));
    const oldUpdates = updateRoles.filter(item => !values.find(i => i.key === item.key));
    setUpdateRoles([...oldUpdates, ...filter]);
  };

  const handleOnChangeRole = ({ target }: CheckboxChangeEvent) => {
    const exists = updateRoles.find(item => item.key === target.name);
    const splitKey = target.name?.split('.');

    const current =
      rolesFields.fields[+(splitKey?.[1] || 0)].permissions[+(splitKey?.[3] || 0)].values.flat(1)[
        +(splitKey?.[5] || 0)
      ];

    setUpdateRoles([
      ...updateRoles.filter(item => item.key !== target.name),
      ...(exists
        ? []
        : [
            {
              key: target.name || '',
              value: target.checked,
              role_id: current.role_id,
              name: `${current.action}_${current.name}`,
              ...(current.permission_id && { permission_id: current.permission_id }),
              ...(current.id && { id: current.id }),
            },
          ]),
    ]);
  };

  const handleGetPermissions = () => {
    setLoading(true);
    Api('permission/manage/roles', 'GET', null)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          const labels = result.permissions
            .filter((i: { name: string }) => i.name.split('_').includes('Read'))
            .map((label: { name: string }) => label.name.split('_')[1]);
          setHeaderLabel(labels);

          const roles = getFilterPermissions(result.data, result.permissions, labels);

          reset({ roles });
          setLoading(false);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const handleSubmit = () => {
    const data = updateRoles.map(i => ({
      value: i.value,
      role_id: i.role_id,
      permission_id: i.permission_id,
      ...(i.id && { id: i.id }),
    }));

    setLoading(true);
    Api('permission/manage/roles/upsert', 'POST', { data })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setLoading(false);
          setRolesModal(false);
          setUpdateRoles([]);
          toastNotification('success', 'Successfully updated!');
          handleGetAuthMe();
          handleGoBack();
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  useEffect(() => {
    handleGetPermissions();
  }, []);

  return (
    <>
      <Form className="permissions-form">
        <Modal
          title="Confim"
          open={rolesModal}
          onOk={handleSubmit}
          confirmLoading={loading}
          onCancel={handleSetRolesModal(false)}
        >
          <p>Are you sure you want to update these permissions?</p>
        </Modal>
        <Space className="permissions-header">
          <Typography.Paragraph className="permissions-title">Role</Typography.Paragraph>
          <PermissionsTableHeader permissionsLabel={headerLabel} editCheckbox={editCheckbox} />
        </Space>
        <PermissionsTableBody
          filedName="roles"
          control={control}
          fields={rolesFields}
          isEditable={editCheckbox}
          handleStatus={handleStatusRoles}
          handleOnChange={handleOnChangeRole}
          handleSetUpdates={handleSetRoleUpdates}
        />
      </Form>
      <PermissionsActions>
        <Space>
          {editCheckbox && (
            <Button className="permissions-save-btn" onClick={handleSetRolesModal(true)}>
              Save
            </Button>
          )}
        </Space>
      </PermissionsActions>
    </>
  );
};
