export const buildTree: any = (categories = []) => {
  let end = true;
  const newArr: any = [];
  categories.forEach((category: any) => {
    const _category: any = {
      id: category.id,
      originalId: category.originalId,
      key: category.originalId,
      name: category.name,
      title: category.name,
      parentId: category.parentId,
      value: category.originalId,
      checkable: true,
      selectable: true,
      className: 'parent-not-clickable',
    };

    const ch = categories
      .filter((_ch: any) => _ch.parentId == category.id)
      .map((c: any) => {
        // c.disable = false;
        c.title = c.name;
        c.key = c.originalId;
        c.selectable = true;
        c.value = c.originalId;
        return c;
      });

    const mountedChildren = category.children
      ? category.children.filter((mCh: any) => {
          const mounted = ch.find((_nCh: any) => _nCh.id == mCh.id);
          return !mounted;
        })
      : [];

    if (ch.length > 0) {
      _category.children = [...mountedChildren, ...ch];
      end = false;
      if (_category?.children?.length == 0) {
        _category.checkable = true;
        _category.selectable = false;
        _category.className = 'parent-not-clickable';
      }
      newArr.push(_category);
    } else {
      if (category.children) {
        _category.children = category.children;
      }
    }

    if (ch.length == 0 && category.parentId == null) {
      if (_category?.children?.length == 0 || !_category?.children) {
        _category.checkable = true;
        _category.selectable = true;
        _category.className = '';
      }

      newArr.push(_category);
    }
  });

  return end ? newArr : buildTree(newArr);
};

export const buildSelectTree: any = (categories = []) => {
  let end = true;
  const newArr: any = [];
  categories.forEach((category: any) => {
    const _category: any = {
      id: category.id,
      originalId: category.originalId,
      title: category?.name || category?.title,
      name: category?.name || category?.title,
      parentId: category?.parentId ?? null,
      value: category.originalId,
      className: '',
    };

    const ch = categories
      .filter((_ch: any) => _ch.parentId == category.id)
      .map((c: any) => {
        // c.disable = false;
        c.title = c.name;
        c.value = c.originalId;
        return c;
      });

    const mountedChildren = category.children
      ? category.children.filter((mCh: any) => {
          const mounted = ch.find((_nCh: any) => _nCh.id == mCh.id);
          return !mounted;
        })
      : [];

    if (ch.length > 0) {
      _category.children = [...mountedChildren, ...ch];
      end = false;

      newArr.push(_category);
    } else {
      if (category.children) {
        _category.children = category.children;
      }
    }

    if (ch.length == 0 && category.parentId == null) {
      newArr.push(_category);
    }
  });

  return end ? newArr : buildSelectTree(newArr);
};
