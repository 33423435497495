import create from 'zustand';

// types
import { Nullable, AuthUserModel } from '../../interfaces';
import { AuthStateTypes } from './authenticated.types';

export const useAuthStore = create<AuthStateTypes>((set: any) => ({
  user: null,
  isAuthenticated: false,
  addAuth: (user: Nullable<AuthUserModel>, isAuthenticated: boolean) => {
    set(() => ({ user, isAuthenticated }));
  },
  removeAuth: () => set(() => ({ user: null, isAuthenticated: false }))
}));
