export const CategoriesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_5504" data-name="Group 5504" transform="translate(-627 -174)">
      <rect
        id="Rectangle_3384"
        data-name="Rectangle 3384"
        width="40"
        height="40"
        rx="20"
        transform="translate(627 174)"
        fill="#ee7f7b"
      />
      <path
        id="categories"
        d="M15.123,8.477v5.169a1.477,1.477,0,0,1-1.477,1.477H8.477A1.477,1.477,0,0,1,7,13.646V8.477A1.477,1.477,0,0,1,8.477,7h5.169A1.477,1.477,0,0,1,15.123,8.477ZM23.985,7H18.815a1.477,1.477,0,0,0-1.477,1.477v5.169a1.477,1.477,0,0,0,1.477,1.477h5.169a1.477,1.477,0,0,0,1.477-1.477V8.477A1.477,1.477,0,0,0,23.985,7ZM13.646,17.338H8.477A1.477,1.477,0,0,0,7,18.815v5.169a1.477,1.477,0,0,0,1.477,1.477h5.169a1.477,1.477,0,0,0,1.477-1.477V18.815A1.477,1.477,0,0,0,13.646,17.338Zm7.754,0A4.062,4.062,0,1,0,25.462,21.4,4.062,4.062,0,0,0,21.4,17.338Z"
        transform="translate(630.769 177.769)"
        fill="#fff"
      />
    </g>
  </svg>
);
