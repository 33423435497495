export const ProductsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_178" data-name="Group 178" transform="translate(-16 -8)">
      <rect
        id="Rectangle_1289"
        data-name="Rectangle 1289"
        width="24"
        height="24"
        transform="translate(16 8)"
        fill="none"
      />
      <g id="Group_5558" data-name="Group 5558" transform="translate(15538 17925)">
        <path
          id="delivery_1_"
          data-name="delivery (1)"
          d="M11.429,2h5.144l0,7.186,0,0-1.421-.711a2.583,2.583,0,0,0-2.3,0l-1.421.711Z"
          transform="translate(-15524 -17919)"
          fill="#52dc95"
        />
        <path
          id="delivery_1_2"
          data-name="delivery (1)"
          d="M26,4.571V23.429A2.575,2.575,0,0,1,23.429,26H4.571A2.575,2.575,0,0,1,2,23.429V4.571A2.575,2.575,0,0,1,4.571,2H9.714V9.185a1.714,1.714,0,0,0,2.481,1.533l1.42-.711a.856.856,0,0,1,.768,0l1.421.711a1.714,1.714,0,0,0,2.481-1.533V2h5.143A2.575,2.575,0,0,1,26,4.571ZM10.571,21.714a.856.856,0,0,0-.857-.857H6.286a.857.857,0,0,0,0,1.714H9.714A.856.856,0,0,0,10.571,21.714Zm1.714-3.429a.856.856,0,0,0-.857-.857H6.286a.857.857,0,0,0,0,1.714h5.143A.856.856,0,0,0,12.286,18.286Z"
          transform="translate(-15524 -17919)"
          fill="#cfd1dc"
        />
      </g>
    </g>
  </svg>
);
