import { FC } from 'react';
import { Space, Typography } from 'antd';

export const PermissionsActions: FC = ({ children }) => (
  <Space
    style={{
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: '20px',
    }}
  >
    <Space style={{ paddingLeft: '8px' }}>
      {['Create', 'Read', 'Update', 'Delete'].map(i => (
        <Typography.Paragraph key={i}>
          <strong>{i.charAt(0)}</strong> - {i}
        </Typography.Paragraph>
      ))}
    </Space>
    {children}
  </Space>
);
