import { DeleteOutlined, PictureOutlined, UploadOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Image, Modal, Row, Tooltip, Upload, UploadFile, message } from 'antd';
import api from '../../../../api';
import { toastNotification } from '../../../../utils/toastNotification';
import { ProductImagesProp } from '../Product.types';
import { useState } from 'react';

export const ProductImages = ({
  images,
  setImages,
  is_variant,
  productId,
  handleGetAllProducts,
  handleGetProductById,
}: ProductImagesProp) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedImage, setSelectedImage] = useState<any>(null);
  
  const setCoverImage = (id: string) => {
    const updatedImages = images.map(image => ({
      ...image,
      is_cover: image.id === id ? true : false,
    }));
    setImages(updatedImages);
  };

  const handleImageClick = (id: string) => {
    const clickedImage = images.find(image => image.id === id);
    setSelectedImage(clickedImage);
  };

  const handleBeforeUpload = (file: UploadFile) => {
    const MAX_FILE_SIZE = 2; // 2MB
    const fileSizeMegaBytes = (file?.size || 0) / 1000000;

    if (fileSizeMegaBytes > MAX_FILE_SIZE) {
      messageApi.open({
        duration: 3,
        type: 'warning',
        content: `Nuk lejohet te ngarkohet nje foto me madhesi me te madhe ${MAX_FILE_SIZE}MB`,
      });
      return false;
    } else return true;
  };

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append('file', file);

    onProgress(true);
    api(`upload`, 'POST', formData)
      .then(({ statusIsOk, result, statusMessage }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          const newImage = {
            id: result.fileId,
            url: result.filePath,
            is_cover: false,
            is_variant,
            productId,
            new_image: true,
            is_deleted: false,
          };

          setImages([...images, newImage]);

          onProgress(false);
          toastNotification('success', 'Successfully image uploaded');
          onSuccess(true);
        }
      })
      .catch(({ statusMessage }) => {
        toastNotification('error', statusMessage);
        onError({ statusMessage });
        onProgress(false);
      });
  };

  const handleRemoveImage = (remove_file: any) => {
    if (remove_file.status !== 'error') {
      const updatedImages = images.map(image => {
        if (image.id === remove_file.id) {
          return {
            ...image,
            is_deleted: true,
          };
        } else {
          return image;
        }
      });
  
      setSelectedImage(null);
      setImages(updatedImages);
      toastNotification('success', 'Image marked for deletion.');
    }
  };

  const findImage: any = images.find((x: any) => x.is_cover);

  return (
    <Row>
      <Col span={24}>
        <div className="images-wrapper">
          <div className="product-images full-height flex__images">
            {findImage && (
              <div className="is_cover-image" key={findImage?.id}>
                <Image
                  src={findImage?.url} 
                  preview={false} 
                  onClick={() => handleImageClick(findImage?.id)} 
                  alt={findImage?.url?.split('/').pop()}
                  style={{ cursor: 'pointer' }}
                />
                <span className="cover-image">Cover</span>
              </div>
            )}
            {images?.length !== 0 ? (
              images?.filter(image => !image.is_deleted).map(({ id, url, is_cover }: any) => {
                if (is_cover != 1) {
                  return (
                    <div className="is_cover-image" key={id}>
                      <Image 
                        src={url} key={id} 
                        preview={false} 
                        onClick={() => handleImageClick(id)} 
                        alt={url?.split('/').pop()} 
                        style={{ cursor: 'pointer' }} 
                      />
                      <span
                        className="cover"
                        style={{ fontSize: '12px' }}
                        onClick={() => setCoverImage(id)}
                      >
                        Set Cover
                      </span>
                    </div>
                  );
                }
              })
            ) : (
              <Avatar
                shape="square"
                size={{ sm: 40, xxl: 100 }}
                alt="Default product image"
                icon={<PictureOutlined />}
              />
            )}
            {contextHolder}
            <Upload
              accept="image/*"
              showUploadList={false}
              customRequest={uploadImage}
              beforeUpload={handleBeforeUpload}
            >
              <div className="is_cover-image upload-btn" style={{ cursor: 'pointer' }}>
                <Avatar
                  shape="square"
                  size={{ sm: 40, xxl: 100 }}
                  icon={<UploadOutlined />}
                />
                <span className="cover">Upload</span>
              </div>
            </Upload>
            <Modal
              visible={!!selectedImage}
              className="upload-list-images"
              footer={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {selectedImage?.name}
                  {selectedImage && (
                    <div>
                      <Tooltip title="Delete">
                        <Button
                          type="link"
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveImage(selectedImage)}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              }
              onCancel={() => setSelectedImage(null)}
            >
              <Image 
                alt={selectedImage?.name}
                style={{ width: '100%' }}
                src={selectedImage?.url}
                preview={false}
              />
            </Modal>
          </div>
        </div>
      </Col>
    </Row>
  );
};
