/* eslint-disable @typescript-eslint/no-var-requires */
export const Images = {
  logo: require('./LogoLogin.svg').default,
  backgroundLogin: require('./login.jpg').default,
  arrowDown: require('./arrow-down.svg').default,
  arrowLeftPurple: require('./arrow-left-purple.svg').default,
  arrowLeft: require('./arrow-left.svg').default,
  arrowRight: require('./arrow-right.svg').default,
  arrowCDown: require('./ArrowDown.svg').default,
  category: require('./category.svg').default,
  delete: require('./delete.svg').default,
  doubleArrow: require('./double-arrow.svg').default,
  Edit: require('./Edit.svg').default,
  eye: require('./eye.svg').default,
  Icon: require('./Icon.svg').default,
  logOut: require('./log-out.svg').default,
  search: require('./Search.svg').default,
  translate: require('./translate.svg').default,
  registerMatric: require('./register-matric.png').default,
  backgroundRegister: require('./newregister.png').default,
  user: require('./user.svg').default,
};
