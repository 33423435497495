export enum ProductStatusEnum {
  NEW = 0,
  PENDING = 1,
  IGNORED = 3,
  APPROVED = 4,
  IN_SHOP = 2,
  REMOVED = 5,
  ARCHIVED = 7
  // LOCAL_SELLER = 6,
}

// export enum LocalProductStatusEnum {
//   OPEN = 0,
//   NOT_APPROVED = 1,
//   APPROVED = 2,
//   PENDING_INFORMATIONS = 3,
//   IN_SHOP = 4,
//   ARCHIVED = 5,
// }

export enum ProductShopStatusEnum {
  PENDING = 'pending',
  IGNORED = 'ignored',
  APPROVED = 'approved',
  IN_SHOP = 'in_shop',
  REMOVED = 'removed',
  ARCHIVED = 'archived',
}