import { Typography } from 'antd';
import * as Icon from 'react-feather';

export interface EmployCheckStatusProps {
  value: boolean;
}

export const EmployCheckStatus = ({ value }: EmployCheckStatusProps) => (
  <Typography.Text className="permissions-check-status">
    {value ? (
      <div className="permissions-check-status">
        <Icon.Check color="#209303" size={12} className="permissions-icon-tick" />
      </div>
    ) : (
      <div className="permissions-check-status-x">
        <Icon.X color="#EF233C" size={12} className="permissions-icon-x" />
      </div>
    )}
  </Typography.Text>
);
