// export enum BusinessTypeEnum {
//   HS = 'Health services',
//   FD = 'Food, Drinks',
//   FS = 'Financial services',
//   TSF = 'Textiles  (clothing, footwear)',
//   SH = 'Shipping (fisheries,inland waterways)',
//   HTC = 'Hotel (tourism ,catering)',
//   PS = 'Public service',
//   C = 'Construcion',
//   PTS = 'Postal and telecommunication services',
// }

export enum BusinessTypeEnum {
  HS = 'Tekstil (veshmbathje)',
  FD = 'Tregtia me paisje kompjuterike',
  FS = 'Shërbime postare dhe telekomunikimi',
  TSF = 'Paisje shëndetësore',
  SH = 'Tregtia me pakicë e artikujve kozmetikë',
  FH = 'Tregtia me pakicë',
}
