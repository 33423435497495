import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

export const toastNotification = (type: string, body: string, args?: Partial<ArgsProps>) => {
  switch (type) {
    case 'error':
      return notification.error({
        message: 'Error',
        description: body,
        duration: 3,
        placement: 'bottomLeft',
        ...args,
      });
    case 'success':
      return notification.success({
        message: 'Success',
        description: body,
        duration: 3,
        placement: 'bottomLeft',
        ...args,
      });
    case 'warn':
      return notification.warn({
        message: 'Warning',
        description: body,
        duration: 3,
        placement: 'bottomLeft',
        ...args,
      });
    case 'info':
      return notification.warn({
        message: 'Info',
        description: body,
        duration: 3,
        placement: 'bottomLeft',
        ...args,
      });
    default:
      return notification.info({
        message: 'Info',
        description: 'Oops!, Something went wrong.',
        duration: 3,
        placement: 'bottomLeft',
        ...args,
      });
  }
};
